import { ActivatedRouteSnapshot } from '@angular/router';
import { Type } from '@angular/core';

const MAX_WIDTH_SYMBOL = Symbol();

function wantsToBeDisplayedAtMaximumWidth(object: any) {
  return !!object[MAX_WIDTH_SYMBOL];
}

export function hasRoutedChildThatWantToBeDisplayedAtMaximumWidth(routeSnapshot: ActivatedRouteSnapshot) {
  const hasChildrenThatWantsMaxWidth = (snapshot: ActivatedRouteSnapshot): boolean => {
    if (snapshot.component && wantsToBeDisplayedAtMaximumWidth(snapshot.component)) return true;

    return snapshot.children.some(child => hasChildrenThatWantsMaxWidth(child));
  };

  return hasChildrenThatWantsMaxWidth(routeSnapshot);
}

/**
 * Attaching this decorator to a component class that is used in routing signals to the component holding the router outlet that
 * this component should be displayed without the default width limit applied. Parts of the component that
 * should still be limited can be marked with the "dt-limit-width" class.
 */
export function DisplayAtMaxWidth(constructor: Type<unknown>) {
  (constructor as any)[MAX_WIDTH_SYMBOL] = true;
}
